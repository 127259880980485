<template>
  <b-card
    v-if="Object.keys(blogEdit).length"
    class="blog-edit-wrapper"
  ><b-overlay
    :show="disabled"
    rounded="sm"
  >
    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col md="6">
          <b-form-group
            label="Title"
            label-for="blog-edit-title"
            class="mb-2"
          >
            <b-form-input
              id="blog-edit-title"
              v-model="blogEdit.title"
              :disabled="disabled"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Section"
            label-for="blog-edit-section"
            class="mb-2"
          >
            <v-select
              v-if="kbSection.length > 0"
              id="blog-edit-section"
              v-model="blogEdit.kbCatID"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="kbSection"
              label="title"
              :disabled="disabled"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Category"
            label-for="blog-edit-category"
            class="mb-2"
          >
            <v-select
              v-if="kbCategory.length > 0"
              id="blog-edit-category"
              v-model="blogEdit.category"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="kbCategory"
              :disabled="disabled"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Subtitle"
            label-for="blog-edit-slug"
            class="mb-2"
          >
            <b-form-input
              id="blog-edit-slug"
              v-model="blogEdit.subtitle"
              :disabled="disabled"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Companies"
            label-for="blog-edit-category"
            class="mb-2"
          >
            <v-select
              v-if="kbCompanies.length > 0"
              id="blog-edit-category"
              v-model="companies"
              label="name"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="kbCompanies"
              multiple
              :disabled="disabled"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <!-- danger -->
          <b-alert
            variant="danger"
            :show="kb_not_posted"
          >
            <h4 class="alert-heading">
              Something went wrong
            </h4>
            <div class="alert-body">
              <span> {{ kb_not_posted_msg }}</span>
            </div>
          </b-alert>
          <b-form-group
            label="Content"
            label-for="blog-content"
            class="mb-2"
          ><div class="document-editor">
          <ckeditor v-model="blogEdit.body" :editor="editor" :config="editorConfig" @ready="onReady"/>
        </div>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            @click="blogEdit.lastModifiedBy = $auth.user.name; postKBArticle();"
          >
            Save Changes
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            @click="
              $router
                .push('/knowledge-base/')
                .catch(() => {})
            "
          >
            Cancel
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-overlay>
  </b-card>
</template>

<script>
import {
  BCard,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BOverlay,
  BAlert,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import CKEditor from '@ckeditor/ckeditor5-vue2'
import Editor from '@core/ckeditor5/build/ckeditor'

export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BOverlay,
    BAlert,
    vSelect,
    ckeditor: CKEditor.component,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      editor: Editor,
      editorData: '<p>Content of the editor.</p>',
      editorConfig: {},
      disabled: false,
      kb_not_posted: false,
      kb_not_posted_msg: 'something went wrong, try again',
      companies: [],
      articleId: '',
      categoryID: '',
      kbCategory: {},
      kbCompanies: {},
      kbSection: {},
      blogEdit: {
        title: '',
        subtitle: '',
        category: '',
        customer: [{ identifier: '', name: '' }],
        author: '',
        body: '',
        body2: '',
        lastModifiedBy: '',
        kbCatID: '',
      },
    }
  },
  async created() {
    this.disabled = true
    await this.getKBCategories()
    this.disabled = false
  },
  methods: {
    onReady(editor) {
      // Insert the toolbar before the editable area.
      editor.ui.getEditableElement().parentElement.insertBefore(
        editor.ui.view.toolbar.element,
        editor.ui.getEditableElement(),
      )
    },
    async getKBCategories() {
      await this.$http
        .get(
          'https://asi-api2.azure-api.net/kb/categories?type=catOnly&key=7d959c93d0ed474abcdf04804842848a',
        )
        .then(response => {
          this.kbCategory = response.data.results.categories
          this.kbCompanies = response.data.results.companies
        })
        .catch(error => {
          console.log(error)
        })
      await this.$http
        .get(
          'https://asi-api2.azure-api.net/kb/categories?key=7d959c93d0ed474abcdf04804842848a',
        )
        .then(response => {
          this.kbSection = response.data.results
        })
        .catch(error => {
          console.log(error)
        })
    },
    async postKBArticle() {
      if (
        this.blogEdit.title
        && this.blogEdit.category
        && this.blogEdit.body
        && this.blogEdit.kbCatID
      ) {
        this.blogEdit.customer = this.companies
        this.disabled = true
        console.log(this.article)
        await this.$http
          .post(
            'https://asi-api2.azure-api.net/kb/article?key=7d959c93d0ed474abcdf04804842848a',
            this.blogEdit,
          )
          .then(response => {
            this.postResponse = response.data
            this.$router.push(`/knowledge-base/${this.postResponse.result.kbCatID}/${this.postResponse.result.UID}`)
          })
          .catch(error => {
            this.kb_not_posted = true
            this.kb_not_posted_msg = 'failed to add article, plesae try again'
            this.disabled = false
            console.log(error)
          })
      } else {
        this.kb_not_posted_msg = 'a required field is not filled out'
        this.kb_not_posted = true
      }
    },

  },
}
</script>


<style lang="css">
.document-editor {
  min-height: calc(
    100vh - 600px
  );
  overflow-y: none; /* add a vertical scroll bar if the content overflows the container */
  border: 1px solid #ccc; /* add a border for visual separation */
  padding: 5px; /* add some padding to the container */
}
.document-editor__toolbar {
  /* Make sure the toolbar container is always above the editable. */
  z-index: 1;

  /* Create the illusion of the toolbar floating over the editable. */
  box-shadow: 0 0 5px hsla( 0,0%,0%,.2 );

  /* Use the CKEditor CSS variables to keep the UI consistent. */
  border-bottom: 1px solid var(--ck-color-toolbar-border);
}

/* Adjust the look of the toolbar inside the container. */
.document-editor__toolbar .ck-toolbar {
  border: 0;
  border-radius: 0;
}
.ck-editor__editable_inline {
    height: calc(
    100vh - 600px
  );
}
</style>